import * as Utils from './utils.js'
import Templates from './templates.js'
import Condition from './conditions.js'
import FieldInputTypes from './fieldInputTypes.js'
import 'globalthis/auto';

export class Component {
    constructor(title, type, typeId, required, options, name, templateId, defaultContent) {
        this.id = Utils.uniqueId();
        this.title = title;
        this.type = type;
        this.typeId = typeId;
        this.required = required;
        this.options = options;
        this.name = name;
        this.templateId = templateId;
        this.content = defaultContent || null;
        this.conditions = [];
        this.currentValues = [];
        this.showAsContent = true;
        this.isSubmissionGps = false;
        this.characterLimits = {
            title: 500,
            options: 500
        };
    }

    toJSON() {
        return {
            id: this.id,
            title: this.title,
            type: this.type,
            typeId: this.typeId,
            required: this.required,
            options: this.options,
            name: this.name,
            templateId: this.templateId,
            content: this.content,
            conditions: this.conditions,
            currentValues: this.currentValues
        }
    }

    render() {
        let template = Object.values(Templates)
            .find(t => t.id === this.templateId);

        this.currentValues = Array.isArray(this.currentValues) ? this.currentValues : [];
        this.hasOptions = Array.isArray(this.options);
        this.hasConditions = Array.isArray(this.conditions);
        this.hasRequired = ![null, undefined].includes(this.required);
        this.isHiddenByDefault = this.conditions.length > 0 ? this.conditions[0].thenRule.isHidden : false;

        if (this.hasOptions) {
            this.options.forEach(option => {
                option.checked = null;
                option.selected = null;

                if (this.currentValues.includes(option.value)) {
                    option.checked = true;
                    option.selected = true;
                }
            });
        }

        return Utils.render(template[globalThis.activeCustomFormTemplate], {
            vm: this,
            isTime: this.typeId === FieldInputTypes.TimeOnly,
            isDate: this.typeId === FieldInputTypes.DateOnly,
            isFileUpload: this.typeId === FieldInputTypes.FileUpload,
            isGeneral: ![FieldInputTypes.DateOnly, FieldInputTypes.TimeOnly, FieldInputTypes.FileUpload].includes(this.typeId),
            validateField() {
                return this.vm.validateField.apply(this.vm);
            }
        });
    }

    validateField() {
        let classes = [
            this.validateTitleCharacterLimit(),
            this.validateOptionsAlert()
        ];

        if (classes.some(x => x.length > 0)) {
            let className = classes.join(' ').trim();

            return `cf-alert ${className}`;
        }

        return '';
    }

    validateOptionsAlert() {
        let className = '';
        let exceedCharacterLimitClassName = ' cf-options-exceeded-character-limit';
        let hasCommaClassName = ' cf-options-has-comma';

        for (let i = 0; i < (this.options || []).length; i++) {
            if ((this.options[i].key && 
                this.options[i].key.length > this.characterLimits.options) ||
                (this.options[i].value && 
                this.options[i].value.length > this.characterLimits.options)) {
                className = className.concat(exceedCharacterLimitClassName);
            }
            
            if(this.options[i].value && this.options[i].value.includes(","))
            {
                className = className.concat(hasCommaClassName);
            }
        }

        return className;
    }

    validateTitleCharacterLimit() {
        let className = 'cf-title-exceeded-character-limit';

        if (this.title.length > this.characterLimits.title) {
            return className;
        }

        return '';
    }

    static parse(componentData = {}) {
        let component = new Component();
        component.id = componentData.id;
        component.title = componentData.title;
        component.type = componentData.type;
        component.typeId = componentData.typeId;
        component.required = componentData.required;
        component.options = componentData.options;
        component.name = componentData.name;
        component.templateId = componentData.templateId;
        component.content = componentData.content;

        (componentData.conditions || [])
            .forEach(conditionData => component.conditions.push(Condition.parse(conditionData)));
        component.currentValues = Array.isArray(componentData.currentValues) ? componentData.currentValues : [];
        component.hasOptions = Array.isArray(componentData.options);
        component.hasRequired = componentData.required !== null || componentData.required != undefined;

        component.isHiddenByDefault = componentData.conditions.length > 0 ? componentData.conditions[0].thenRule.isHidden : false;
        return component;
    }
}

export const ComponentTemplates = [{
    name: 'header',
    title: 'Header',
    icon: 'H',
    headerclass: 'cf-icon-header',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.Header, null, null, this.name, Templates.HeaderTemplate.id, null);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'label',
    title: 'Read only text',
    icon: 'comment',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.ReadOnlyText, null, null, this.name, Templates.LabelTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'input',
    title: 'Single line text',
    icon: 'text_rotation_none',
    copyTo(section, index) {
        let component = new Component(this.title, 'text', FieldInputTypes.SingleLineText, false, null, this.name, Templates.InputTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'paragraph',
    title: 'Paragraph text',
    icon: 'format_align_left',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.MultiLineText, false, null, this.name, Templates.TextareaTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'date',
    title: 'Date Picker',
    icon: 'calendar_today',
    copyTo(section, index) {
        let component = new Component(this.title, 'date', FieldInputTypes.DateOnly, false, null, this.name, Templates.InputTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'time',
    title: 'Time Select',
    icon: 'query_builder',
    copyTo(section, index) {
        let component = new Component(this.title, 'time', FieldInputTypes.TimeOnly, false, null, this.name, Templates.InputTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'number',
    title: 'Number',
    icon: 'exposure_plus_1',
    copyTo(section, index) {
        let component = new Component(this.title, 'number', FieldInputTypes.Number, false, null, this.name, Templates.InputTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'file',
    title: 'Upload File',
    icon: 'attachment',
    copyTo(section, index) {
        let component = new Component(this.title, 'file', FieldInputTypes.FileUpload, false, null, this.name, Templates.InputTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'checkboxgroup',
    title: 'Checkbox Group',
    icon: 'check_box',
    copyTo(section, index) {
        let options = [...Array(3)
            .keys()
        ].map((item) => {
            return {
                key: Utils.uniqueId(),
                value: `Checkbox ${item}`
            }
        });

        let component = new Component(this.title, null, FieldInputTypes.MultiSelect, false, options, this.name, Templates.CheckboxGroupTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'radiogroup',
    title: 'Radio Group',
    icon: 'radio_button_checked',
    copyTo(section, index) {
        let options = [...Array(3)
            .keys()
        ].map((item) => {
            return {
                key: Utils.uniqueId(),
                value: `Radio ${item}`
            }
        });

        let component = new Component(this.title, null, FieldInputTypes.SingleSelectOptions, false, options, this.name, Templates.RadioGroupTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'dropdownlist',
    title: 'Dropdown List',
    icon: 'arrow_drop_down_circle',
    copyTo(section, index) {
        let options = [...Array(3)
            .keys()
        ].map((item) => {
            return {
                key: Utils.uniqueId(),
                value: `Option ${item}`
            }
        });

        let component = new Component(this.title, null, FieldInputTypes.SingleSelectList, false, options, this.name, Templates.DropdownListTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'signaturepad',
    title: 'Draw or Sign',
    icon: 'create',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.DrawPad, false, null, this.name, Templates.SignaturePadTemplate.id);
        section.components.splice(index, 0, component);
    }
},
{
    name: 'vehiclecanvas',
    title: 'Vehicle Canvas',
    icon: 'directions_car',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.VehicleCanvas, false, null, this.name, Templates.VehicleCanvasTemplate.id);
        section.components.splice(index, 0, component);
    }
}
];

export const JobComponentTemplates = [{
    name: 'ticketnumber',
    title: 'Ticket #',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_TICKETNUMBER}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'branch',
    title: 'Branch',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_BRANCH}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'location',
    title: 'Job Location',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_LOCATION}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'startdate',
    title: 'Start Date',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_STARTDATE}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'clientcompany',
    title: 'Client Company',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_CLIENTCOMPANY}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'sitecontact',
    title: 'Site Contact',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_SITECONTACT}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'timesheetmanager',
    title: 'Timesheet Manager',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_TIMESHEETMANAGER}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'submittedby',
    title: 'Submitted By',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_SUBMITTEDBY}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'submissionemail',
    title: 'Submission Email',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_SUBMISSIONEMAIL}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'submissionphone',
    title: 'Submission Phone',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{JOB_SUBMISSIONPHONE}');
        section.components.splice(index, 0, component);
    }
},
{
    name: 'submissiongps',
    title: 'Submission GPS',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{FORM_SUBMISSIONGPS}')
        section.components.splice(index, 0, component);
        component.isSubmissionGps = true;
        component.showAsContent = true;
    }
}
];

export const AssetComponentTemplates = [{
    name: 'assetdescription',
    title: 'Asset Description',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{ASSET_DESCRIPTION}');
        section.components.splice(index, 0, component);
    }
}];

export const VehicleComponentTemplates = [{
    name: 'registrationnumber',
    title: 'Registration Number',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{VEHICLE_REGISTRATIONNUMBER}');
        section.components.splice(index, 0, component);
    }
}, {
    name: 'vehicledescription',
    title: 'Vehicle Description',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{VEHICLE_DESCRIPTION}');
        section.components.splice(index, 0, component);
    }
}, {
    name: 'operatorname',
    title: 'Operator Name',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{VEHICLE_OPERATORNAME}');
        section.components.splice(index, 0, component);
    }
},];

export const WorkerComponentTemplates = [{
    name: 'workername',
    title: 'Worker Name',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{WORKER_FULLNAME}');
        section.components.splice(index, 0, component);
    }
}, {
    name: 'shifttype',
    title: 'Shift Type',
    copyTo(section, index) {
        let component = new Component(this.title, null, FieldInputTypes.PrePopulated, null, null, this.name, Templates.SystemField.id, '{WORKER_SHIFTTYPE}');
        section.components.splice(index, 0, component);
    }
}];

export const ComponentTemplateGroups = [{
    groupKey: 'Form',
    groupName: 'Form Elements',
    componentTemplates: [...ComponentTemplates]
},
{
    groupKey: 'Job',
    groupName: 'Job Fields',
    componentTemplates: [...JobComponentTemplates]
},
{
    groupKey: 'Worker',
    groupName: 'Worker Fields',
    componentTemplates: [...WorkerComponentTemplates]
},
{
    groupKey: 'Asset',
    groupName: 'Asset Fields',
    componentTemplates: [...AssetComponentTemplates]
},
{
    groupKey: 'Vehicle',
    groupName: 'Vehicle Fields',
    componentTemplates: [...VehicleComponentTemplates]
}
];