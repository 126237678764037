import {
    Component,
    JobComponentTemplates
} from './components.js';

import * as Utils from './utils.js'

export class Form {
    constructor() {
        this.id = Utils.uniqueId();
        this.header = new FormSection();
        this.sections = [];

        // Ticket number is expected to always be a part of the form. Users do 
        let ticketNumberComponent = JobComponentTemplates.find(t => t.name === 'ticketnumber');
        ticketNumberComponent.copyTo(this.header);
    }

    static parse(formData = {}) {
        let form = new Form();
        form.id = formData.id;
        form.header = FormSection.parse(formData.header);
        (formData.sections || [])
        .forEach(sectionData => {
            let section = FormSection.parse(sectionData);
            //hide submission gps section in mobile when its the only component in the section 
            let isSectionContainsSubmissionGps = sectionData.components.every(x => x.content == '{FORM_SUBMISSIONGPS}');
            section.hideSectionInMobile = isSectionContainsSubmissionGps;
            form.sections.push(section)
        });
        return form;
    }
}

export class FormSection {
    constructor() {
        this.id = Utils.uniqueId();
        this.components = [];
    }

    static parse(sectionData = {}) {
        let section = new FormSection();
        section.id = sectionData.id;
        (sectionData.components || [])
        .forEach(componentData => section.components.push(Component.parse(componentData)));
        return section;
    }
}